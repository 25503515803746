
import Vue from "vue";

import ActivitiesView from "@/components/ActivitiesView/ActivitiesView.vue";
import ActivitiesTimelineItem from "@/components/ActivitiesView/ActivitiesTimelineItem.vue";

interface ICountiesActivitiesData {
  error: string;
  message: string;
}

export default Vue.extend({
  name: "countiesActivitiesView",
  components: {
    ActivitiesView,
    ActivitiesTimelineItem
  },
  data(): ICountiesActivitiesData {
    return {
      error: "",
      message: ""
    };
  },
  computed: {
    queryOverride(): any {
      return {
        "data.countyId": this.$route.params.countyId,
        activityType: "CountyActivity"
      };
    }
  }
});
